<template>
  <div class="mobile-nav-create d-flex align-center justify-space-between elevation-10 gradientBG">

    <a href="/" class="d-flex align-center ml-1 logo">
      <img class="logo" src="@/assets/Youtorial.png">
    </a>

    <!-- <v-toolbar-side-icon></v-toolbar-side-icon> -->
    <!-- <v-toolbar-title>Youtorial</v-toolbar-title> -->
    <v-spacer></v-spacer>

      <!-- if user IS NOT authed -->
      <template v-if="!isAuthenticated">
        <!-- <v-btn text small large class="ma-0 mr-2" @click="$router.push({name: 'SignUp'})">Sign up</v-btn>
        <v-btn small class="primary ma-0" @click="$router.push({name: 'Login'})">Login</v-btn> -->
        <v-btn text small color="primary darken-1" class="ma-0" @click="$router.push({name: 'build'})">
          <v-icon class="mr-2">video_library</v-icon>
          Create
        </v-btn>
      </template>
      <!-- if user IS authed -->
      <template v-else>
        <v-menu
            transition="slide-x-transition"
            bottom
            right
            content-class="main-menu"
            :z-index="500"
            min-width="150"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                text
                v-on="on"
              >
                Menu
              </v-btn>
            </template>

            <v-list>

              <template v-if="isAuthenticated && user">
                <v-list-item  class="secondary--text text--darken-2" @click="$router.push({name:'create', params: {videoId: $route.params.videoId}})">
                  <v-list-item-title><v-icon color="secondary darken-2" class="mr-1">library_books</v-icon> Edit</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
              </template>

              <template v-if="isAuthenticated && user">
                <v-list-item  class="primary--text text--darken-2" @click="$router.push({name: 'build'})">
                  <v-list-item-title><v-icon color="primary darken-1" class="mr-1">video_library</v-icon> Create</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
              </template>

              <v-list-item @click="$router.push({name: 'home'})">
                <v-list-item-title>Home</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push({name: 'myYoutorials'})">
                <v-list-item-title>My Youtorials</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="logOut">
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
      </template>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "mobileNav",
  props:['userId'],
  computed:{
    isAuthenticated: function (){ return this.$store.state.userId},
    ...mapGetters({
      user:'GET_USER_ID',
    }),
  },
  data(){
    return{

    }
  },
  methods:{
    logOut(){
      this.$store.dispatch('logout');
      this.$router.replace('home')
    }
  }
};
</script>

<style lang="scss">
  .mobile-nav{
    width: 100%;
    z-index: 999999;
    height: 44px;

    .v-btn{height: 40px!important; border-radius: 6px;}
    .logo{
      max-width:120px;
    }
  }
</style>
